import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppCommonService } from '@common/services';
import { CustomersService } from '@modules/customers/services';
import { ValidatorService } from '@modules/validators/validator.service';
import { ConfirmacionMComponent } from '../confirmacion-m/confirmacion-m.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'gk-doctor-m',
  templateUrl: './doctor-m.component.html',
  styleUrl: './doctor-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoctorMComponent implements OnInit{

  //? combos recibidos
  combos: any;
  institucionid: any;
  doctor: any
  NombreFirma:string=''; 
  Firma:string='';
  @Output() doctorAdded = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef | undefined;

  private customersService = inject( CustomersService );
  private fb = inject(FormBuilder);
  private vS = inject(ValidatorService);
  private dialogRef= inject(MatDialogRef<DoctorMComponent>);
  private dialog = inject(MatDialog);
  public appCommon= inject(AppCommonService);
  private router = inject(ActivatedRoute);

  esVisor:boolean = false;

  public formDoctor: FormGroup = this.fb.group({
    UsuarioId: [],
    Nombre: [],
    Celular: [],
    Correo: [],
    Especialidad1: [],
    Cedula1: [],
    Especialidad2: [],
    Cedula2: [],
    Especialidad3: [],
    Cedula3: [],

  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,){
    if(this.data && this.data.combos  && this.data.institucionid  && !this.data.doctor){
      this.combos = this.data.combos;
      this.institucionid = this.data.institucionid;
    }
    if(this.data && this.data.combos && this.data.institucionid  && this.data.doctor ){
      this.combos = this.data.combos;
      this.institucionid = this.data.institucionid;
      this.doctor = this.data.doctor;
    }
  }

  ngOnInit(): void {
    this.router.queryParams.subscribe(params => {
      if (params['esVisor'] === 'true') {
          this.esVisor = true;
      }
    });
    if(this.doctor){
      this.formDoctor.patchValue({
        UsuarioId: this.doctor.UsuarioId , Nombre: this.doctor.Nombre, Celular: this.doctor.Celular,
        Correo: this.doctor.Correo, Especialidad1: this.doctor.Especialidad1, Especialidad2: this.doctor.Especialidad2, 
        Especialidad3: this.doctor.Especialidad3  , Cedula1: this.doctor.Cedula1,  Cedula2: this.doctor.Cedula2,  Cedula3: this.doctor.Cedula3,
      })
      if(this.doctor.Firma){
        this.Firma = this.doctor.Firma;
      }
      this.NombreFirma = this.doctor.NombreFirma;
    }
  }

  archivoSeleccionado(event:any) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.NombreFirma = selectedFile.name;
      this.vS.convertToBase64(selectedFile).then((base64String: string) => {
        this.Firma = base64String;
      });
    }else{
      this.NombreFirma = "";
      this.Firma = "";
    }
  }


  addDoctor(){

    if(this.formDoctor.invalid){
      console.log(this.formDoctor.value);
      return
    }

    this.appCommon.spinner(true);

    //? Desestructuro el objeto 
    const formValue = { ...this.formDoctor.value }

    formValue.institucionid = this.institucionid;
    formValue.NombreFirma = this.NombreFirma;
    formValue.Firma = this.Firma;
    if(this.doctor){
      formValue.Id = this.doctor.Id;
    }

    console.log(formValue);
    

    this.customersService.addDoctorEco(formValue)
      .subscribe({
        next: (resp: any) => {
          this.appCommon.spinner(false);
          const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: `Doctor agregado`, 
                    mensaje: `Se ha guardado el doctor`,
                    Noconfirm: true
                }
          })
          dialogRefM.afterOpened().subscribe(() => {
            setTimeout(() => {
              dialogRefM.close();
            }, 3000);
          });
          this.doctorAdded.emit(resp.data);
          this.dialogRef.close();
          console.log('Doctor guardado',resp);          
        },
        error: err => {
          this.appCommon.spinner(false);
          const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: `No se pudo guardar la informacion`, 
                    mensaje: `A ocurrido un error al querer guardar el doctor`,
                    Noconfirm: true
                }
          })

          dialogRefM.afterOpened().subscribe(() => {
            setTimeout(() => {
              dialogRefM.close();
            }, 3000);
          });
          console.log('error al enviar la informacion', err);
          
        }
      })

  }

}
