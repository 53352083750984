<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-12">
            @if(!doctor){
                <h2 mat-dialog-title class="text-center">Nuevo Doctor</h2>
            }@else{
                <h2 mat-dialog-title class="text-center">Editar Doctor:</h2>
            }
        </div>
    </div>
    <form [formGroup]="formDoctor" (ngSubmit)="addDoctor()" >
        <div class="row">

            <label class="control-label col-2 text-center mb-5" >Usuario:</label>
            <div class="col-4" >
                <select  class="form-control"  formControlName="UsuarioId" >
                    <option value="">Seleccione un tipo</option>
                    <option
                            *ngFor="let usuario of combos.UsuariosECO"
                            [value]="usuario.value"
                            >
                            {{ usuario.display }}
                    </option>
                </select>
            </div>
            <div class="col-6"></div>

            <label class="control-label col-2  text-center mb-4" >Nombre:</label>
            <div class="col-10" >
                <input type="text" class="form-control" formControlName="Nombre">
            </div>

            <label class="control-label col-2  text-center mb-4">Celular:</label>
            <div class="col-3">
                <input type="text" class="form-control" formControlName="Celular">
            </div>
            <div class="col-1"></div>
            <label class="control-label col-2 text-center">Correo:</label>
            <div class="col-4">
                <input type="text" class="form-control" formControlName="Correo">
            </div>
            
            <label class="control-label col-2  text-center mb-4" >Especialidad 1:</label>
            <div class="col-3">
                <input type="text" class="form-control" formControlName="Especialidad1">
            </div>
                <label class="control-label text-end col-3" >Cédula Especialidad 1:</label>
            <div class="col-4">
                <input type="text" class="form-control" formControlName="Cedula1">
            </div>

            <label class="control-label col-2  text-center mb-4" >Especialidad 2:</label>
            <div class="col-3">
                <input type="text" class="form-control" formControlName="Especialidad2">
            </div>
                <label class="control-label text-end col-3" >Cédula Especialidad 2:</label>
            <div class="col-4">
                <input type="text" class="form-control" formControlName="Cedula2">
            </div>

            <label class="control-label col-2  text-center mb-4" >Especialidad 3:</label>
            <div class="col-3">
                <input type="text" class="form-control" formControlName="Especialidad3">
            </div>
                <label class="control-label text-end col-3" >Cédula Especialidad 3:</label>
            <div class="col-4">
                <input type="text" class="form-control" formControlName="Cedula3">
            </div>

            <label class="control-label col-2  text-center mb-4" >Firma:</label>
            <div class="col-4">
                <input type="file" class="form-control" #fileInput (change)="archivoSeleccionado($event)">
            </div>


        </div>

        <div class="full-width-element full-height-element">
            <mat-divider class="mt-4"></mat-divider>
            <mat-dialog-actions align="end" class="gris py-4">
                <button cdkFocusInitial class="btn btn-success mx-2 mt-3" type="submit" *ngIf="!esVisor">
                    <mat-icon class="tamIconB">save</mat-icon>
                    Guardar
                </button>
                <button [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">
                    <mat-icon class="tamIconB">keyboard_arrow_left</mat-icon>
                    Regresar
                </button>
            </mat-dialog-actions>
        </div>

    </form>

</mat-dialog-content>