import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable, of } from 'rxjs';

import { UserService } from '@modules/auth/services';
import { AccountsEmployee, AddNumbersCT, CheckErrorPf, Default, DoTrad, GetResponseAccountsEmployee, GetResponseCheckErrorPf, GetResponseModalDetalleError, GetResponseResultDln, GetResponseTradEmails, GetResponseValidacionesModalDln, GetResponseValidarParametroDln, InfoComents, ModalDetalleError, PostResponseAddNumbersCT, PostResponseDoTrad, PostResponseInfoComents, PostResponseValidateNumbersCT, ResultDln, ValidacionesModalDln, ValidaNumerosCT, ValidarParametroDln, PostResponseSaveComments, SaveComments, GetResponseValidacionesModalFln, ValidacionesModalFln, GetResponseTradEmailsFln, CasesOpBody, GetResponseCasesOpBody, PostResponseCasesOpMBody, PostResponseCaseOpM, CaseOpM, PostResponseCaseOpFile, CaseOpFile, PostResponseCaseOpSendEmail, CaseOpSendEmail, GetResponseCaseOpFileM, CaseOpFileM, GetResponseDxData, DxData } from '../models';

@Injectable({ providedIn: 'root' })
export class OperationsTkService {

    private http = inject(HttpClient);
    private userService = inject(UserService);

    getOperationsTk$(): Observable<{}> {
        return of({});
    }

    MEDICAL_TK_REPORTS = environment.api.medicalReportsTK

    getAccountsEmployee():Observable<AccountsEmployee>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/trad/ptes`;
        return this.http.get<GetResponseAccountsEmployee>(  url, { headers } )
        .pipe(
            map( resp => resp.data )
        )
    }

    getModalErrorDetail(id: number | string):Observable<ModalDetalleError>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/trad/errordet/${id}`;
        return this.http.get<GetResponseModalDetalleError>(  url, { headers } )
        .pipe(
            map( resp => resp.data )
        )
    }

    postCheckErrorPf(pf):Observable<CheckErrorPf>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/trad/error`;
        return this.http.post<GetResponseCheckErrorPf>(  url, pf , { headers } )
        .pipe(
            map( resp => resp.data )
        )
    }

    getResultDln(id: number | string):Observable<ResultDln>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/data/${id}`;
        return this.http.get<GetResponseResultDln>(  url, { headers } )
        .pipe(
            map( resp => resp.data )
        )
    }

    getResultFln(id: number | string):Observable<ResultDln>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/data/${id}`;
        return this.http.get<GetResponseResultDln>(  url, { headers } )
        .pipe(
            map( resp => resp.data )
        )
    }

    getDoValAll(id: number | string):Observable<ResultDln>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/do/val-all/${id}`;
        return this.http.get<GetResponseResultDln>(  url, { headers } )
        .pipe(
            map( resp => resp.data )
        )
    }

    getReturnPF(id: number | string):Observable<Default>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/trad/return/${id}`;
        return this.http.get<Default>(  url, { headers } )
        .pipe(
            map( resp => resp.data )
        )
    }


    getDetDLN(cuerpo): Observable<ValidacionesModalDln> {

        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/det`;

        return this.http.post<GetResponseValidacionesModalDln>(  url, cuerpo, { headers } )
        .pipe(
            map( resp => resp.data )
        )

    }

    getDetFLN(cuerpo): Observable<ValidacionesModalFln> {

        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/det`;

        return this.http.post<GetResponseValidacionesModalFln>(  url, cuerpo, { headers } )
        .pipe(
            map( resp => resp.data )
        )

    }

    async getAcomodo(id: number) {
        return new Promise((resolve, reject) => {
            this.http
                .get<Default>(`${this.MEDICAL_TK_REPORTS}/tk/pf/acomodo/search/${id}`)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async saveAcomodo(requestBody: any) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http.post<Default>(`${this.MEDICAL_TK_REPORTS}/tk/pf/acomodo/save`, requestBody, { headers }).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }

    postValidarParametroDln(parametro): Observable<ValidarParametroDln>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/do/val`;
        return this.http.post<GetResponseValidarParametroDln>(  url, parametro , { headers } ).pipe(
            map( resp => resp.data )
        )
    }
    
    postValidarParametroFln(parametro): Observable<ValidarParametroDln>{
        
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/do/val`;
        return this.http.post<GetResponseValidarParametroDln>(  url, parametro , { headers } ).pipe(
            map( resp => resp.data )
        )
    }

    postObtenerInfoComents(parametros): Observable<InfoComents>{
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/trad/com/get`;
        return this.http.post<PostResponseInfoComents>(  url, parametros , { headers } ).pipe(
            map( resp => resp.data )
        )
    }

    postAddNumberCT(parametros): Observable<AddNumbersCT>{
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/trad/com/get/add-numbers`;
        return this.http.post<PostResponseAddNumbersCT>(  url, parametros , { headers } ).pipe(
            map( resp => resp.data )
        )
    }

    postValidateNumbersCT(parametros): Observable<ValidaNumerosCT>{
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/trad/com/get/validate-numbers`;
        return this.http.post<PostResponseValidateNumbersCT>(  url, parametros , { headers } ).pipe(
            map( resp => resp.data )
        )
    }

    postSaveComments(params): Observable<SaveComments>{
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/trad/com/save`;
        return this.http.post<PostResponseSaveComments>(  url, params , { headers } ).pipe(
            map( resp => resp.data)
        )
    }

    postDoTrad(parametros): Observable<DoTrad>{
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/do/trad`;
        return this.http.post<PostResponseDoTrad>(  url, parametros , { headers } ).pipe(
            map(resp => resp.data)
        )
    }

    getTradEmails(id: number | string){
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/dln/do/mails/${id}`;
        return this.http.get<GetResponseTradEmails>(  url, { headers } )
    }

    getTradEmailsFln(id: number | string){
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/valid/fln/do/mails/${id}`;
        return this.http.get<GetResponseTradEmailsFln>(  url, { headers } )
    }

    getCasesOp():Observable<CasesOpBody>{
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/casos-op/correos/get`;
        return this.http.get<GetResponseCasesOpBody>(  url, { headers } ).pipe(
            map( resp => resp.data )
        )
    }

    postCasesOpBody(correos){
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/casos-op/correos/body`;
        return this.http.post<PostResponseCasesOpMBody>(  url, correos, { headers } )
    }

    postCasesOp(correos): Observable<CaseOpM>{
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/casos-op/correos/det`;
        return this.http.post<PostResponseCaseOpM>(  url, correos, { headers } ).pipe(
            map( resp => resp.data)
        )
    }

    postCaseOpAddFile(archivo): Observable<CaseOpFile>{
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/casos-op/correos/adj`;
        return this.http.post<PostResponseCaseOpFile>(  url, archivo, { headers } ).pipe(
            map( resp => resp.data)
        )
    }

    postCaseOpSendEmail(correo): Observable<CaseOpSendEmail>{
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/casos-op/correos/send`;
        return this.http.post<PostResponseCaseOpSendEmail>(  url, correo, { headers } ).pipe(
            map( resp => resp.data)
        )
    }

    getViewFileCasesOpM(id: string | number):Observable<CaseOpFileM>{
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/casos-op/correos/adj/${id}`;
        return this.http.get<GetResponseCaseOpFileM>(  url, { headers } ).pipe(
            map( resp => resp.data )
        )
    }

    getDxData(id: string | number): Observable<DxData> {
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/dx/${id}`;
        return this.http.get<GetResponseDxData>(  url, { headers } ).pipe(
            map( resp => resp.data )
        )
    }

}
