/*
    All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free
    Mas configuraciones
    https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/features.md
 */

import {
    faComments,
    faAngleDown,
    faAngleRight,
    faArrowLeft,
    faCalendar,
    faBars,
    faBookOpen,
    faChartArea,
    faChartBar,
    faChartPie,
    faChevronDown,
    faChevronUp,
    faColumns,
    faSearch,
    faTable,
    faTachometerAlt,
    faUser,
    faHouseUser,
    faTools,
    faCogs,
    faAddressBook,
    faClock,
    faInfo,
    faTasks,
    faBell,
    faTicketAlt,
    faEdit,
    faDna,
    faFeatherAlt,
    faFilter,
    faEye,
    faEnvelope, // Email
    faEnvelopeOpen,
    faAddressCard,
    faFolder,
    faInfoCircle,
    faMinus,
    faPlus,
    faCheck,
    faThumbsUp,
    faThumbsDown,
    faTimes,
    faFolderOpen,
    faReply,
    faLock,
    faList,
    faTrashAlt,
    faLaptop,
    faCamera,
    faDollarSign,
    faSpinner,
    faFileUpload,
    faFile,
    faCheckSquare,
    faSave,
    faRedo,
    faFileDownload,
    faPaperclip,
    faSync,
    faSearchPlus,
    faMale,
    faFemale,
    faTimesCircle,
    faCheckCircle,
    faExclamationTriangle,
    faUndo,
    faMoneyBill,
    faFileExcel,
    faFilePdf,
    faExclamationCircle,
    faEyeSlash,
    faGift,
    faFlask,
    faPlusSquare,
    faSortDown,
    faMinusSquare,
    faTint,
    faDownload,
    faFileAlt,
    faImage
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeSolidIcons = {
    faComments,
    faAngleDown,
    faAngleRight,
    faArrowLeft,
    faCalendar,
    faBars,
    faBookOpen,
    faChartArea,
    faChartBar,
    faChartPie,
    faChevronDown,
    faChevronUp,
    faColumns,
    faSearch,
    faTable,
    faTachometerAlt,
    faUser,
    faHouseUser,
    faInfoCircle,
    faTools,
    faCogs,
    faAddressBook,
    faClock,
    faInfo,
    faTasks,
    faBell,
    faTicketAlt,
    faEdit,
    faDna,
    faFeatherAlt,
    faFilter,
    faEye,
    faEnvelope,
    faEnvelopeOpen,
    faAddressCard,
    faFolder,
    faMinus,
    faPlus,
    faCheck,
    faThumbsUp,
    faThumbsDown,
    faTimes,
    faFolderOpen,
    faReply,
    faLock,
    faList,
    faTrashAlt,
    faLaptop,
    faCamera,
    faDollarSign,
    faSpinner,
    faFileUpload,
    faFile,
    faCheckSquare,
    faSave,
    faRedo,
    faFileDownload,
    faPaperclip,
    faSync,
    faSearchPlus,
    faMale,
    faFemale,
    faTimesCircle,
    faCheckCircle,
    faExclamationTriangle,
    faUndo,
    faMoneyBill,
    faFileExcel,
    faFilePdf,
    faExclamationCircle,
    faEyeSlash,
    faGift,
    faFlask,
    faPlusSquare,
    faSortDown,
    faMinusSquare,
    faTint,
    faDownload,
    faFileAlt,
    faImage
};
