import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
//import { CKEditorComponent, CKEditorModule } from '@ckeditor/ckeditor5-angular';
//import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AppCommonService } from '@modules/app-common/services';
import { IconsModule } from '@modules/icons/icons.module';
import { GkFormsModule } from '@modules/shared/genomik-forms/genomik-forms.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface MailDialogForm {
    AlertaId: number;
    Body: string;
    Attachment?: string;
    To: string[];
    Bcc: string[];
    Subject: string;
    ExtraBody: string;
    From: string;
    Demo: number
}

export interface MailDialogData {
    AlertaId: number;
    Nota: string;
    Titulo: string;
    To: string[];
    From: string;
    Bcc: string[];
    Subject: string;
    Body: string;
    extraBody: string;
    Attachment: string;
    Demo: number
}

export type MailDialogResult = MailDialogForm;

@Component({
    standalone: true,
    // imports: [CommonModule, CKEditorModule, IconsModule, GkFormsModule],
    imports: [CommonModule, IconsModule, GkFormsModule],
    selector: 'gk-mail',
    templateUrl: './mail.component.html',
    styleUrls: ['mail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailComponent {
   // @ViewChild(CKEditorComponent, { static: false }) editorComponent!: CKEditorComponent;
   // readonly editor = ClassicEditor;
    readonly editorConfig = {
        toolbar: [['heading'], ['Bold'], ['Italic'], ['bulletedList'], ['numberedList'], ['link']],
    };

    form: UntypedFormGroup;
    note = '';
    showAttachment = false;
    /**
     * Can be used to add suggested emails
     */
    listOfEmailOptions: Array<{ label: string; value: string }> = [];
    Titulo: any;
    Attachment: any;
    Bcc: any;
    From: any;
    extraBody: any;
    Demo: any

    constructor(private activeModal: NgbActiveModal, private appCommon: AppCommonService) {
        this.form = new UntypedFormGroup({
            To: new UntypedFormControl([], Validators.required),
            Subject: new UntypedFormControl('', Validators.required),
            Attachment: new UntypedFormControl([]),
            Body: new UntypedFormControl(''),
            ExtraBody: new UntypedFormControl(''),
            AlertaId: new UntypedFormControl(''),
            Bcc: new UntypedFormControl(''),
            From: new UntypedFormControl(''),
            Demo: new UntypedFormControl(0),
        });
    }

    cleanMail() {
        this.note = '';

        this.form.reset({
            AlertaId: '',
            Subject: '',
            To: [],
            Bcc: [],
            Body: '',
            ExtraBody: '',
            Attachment: [],
            Demo: 0,
        });
    }

    load(params: MailDialogData) {
        let Demo = 0
         if(location.hostname.toLowerCase().indexOf("localhost") != -1) {
            Demo = 1
        }
        this.note = params.Nota;
        this.Titulo = params.Titulo;

        this.form.patchValue({
            AlertaId: params.AlertaId,
            Body: params.Body,
            Attachment: params.Attachment,
            To: params.To,
            Bcc: params.Bcc,
            Subject: params.Subject,
            ExtraBody: params.extraBody,
            From: params.From,
            Demo: Demo
        });
        this.Attachment = params.Attachment;
        this.Bcc = params.Bcc;
        this.From = params.From;
        this.extraBody = params.extraBody;
        this.showAttachment = false;
    }

    toggleAttachment() {
        this.showAttachment = !this.showAttachment;
    }

    validateForm() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.send(this.form.value);
    }

    async send(requestBody: MailDialogForm) {
        this.appCommon.spinner(true);
        this.appCommon
            .sendEmail(requestBody)
            .then((response: any) => {
                this.appCommon.spinner(false);
                this.appCommon.Notify(response.message, 'success');
                // si se manda sin error, cierra modal y regresa los valores del `form`
                // para poder seguir usándolos en la cadena de promises
                this.close(requestBody);
            })
            .catch(error => {
                console.log('fail callback', error);
                this.appCommon.Notify(error.data.message, 'danger');
                this.appCommon.spinner(false);
            });
    }

    close(result: MailDialogResult) {
        this.activeModal.close(result);
    }

    dismiss() {
        this.activeModal.dismiss();
    }
}
