import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { FormGroup, } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CustomersService } from '@modules/customers/services';
import { ValidatorService } from '@modules/validators/validator.service';
import { ConvenioMComponent } from '../Modales/convenio-m/convenio-m.component';
import { TamizajeMComponent } from '../Modales/tamizaje-m/tamizaje-m.component';
import { PaqueteMaterMComponent } from '../Modales/paquete-mater-m/paquete-mater-m.component';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TablaPreciosMComponent } from '../Modales/tabla-precios-m/tabla-precios-m.component';
import { DoctorMComponent } from '../Modales/doctor-m/doctor-m.component';
import { ConfirmacionMComponent } from '../Modales/confirmacion-m/confirmacion-m.component';
import { AppCommonService } from '@common/services';
import { ShowImageMComponent } from '../Modales/show-image-m/show-image-m.component';

@Component({
    selector: 'sb-general-tab',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './general-tab.component.html',
    styleUrls: ['general-tab.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(300)),
        ])
    ]
})
export class GeneralTabComponent implements OnInit {


    //ToDos: Servicios
    private dialog = inject(MatDialog);
    private router = inject(ActivatedRoute);
    private customersServices = inject(CustomersService);
    public appCommon= inject(AppCommonService);
    private vS = inject(ValidatorService);
    
    

    //ToDos: variables locales
    @Input() combos:any;
    @Input() radios:any;
    @Input() checkboxes:any;
    @Input() tabs:any;
    @Input() requestbody:any;

    @Output() dataChangedPaquetes = new EventEmitter<any>();
    @Output() dataChangedTamizajes = new EventEmitter<any>();
    @Output() dataChangedConvenio = new EventEmitter<any>();
    @Output() dataChangedDoctorEco = new EventEmitter<any>();

    esVisor: boolean = false;

    constructor(private cdr: ChangeDetectorRef){}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tabs) {
            this.cdr.detectChanges();
        }
    }
    
    message = 'Conexión a tab general!';

    //ToDoS: variabels para ocultar y mostrar
    ocultarTamizaje: boolean = false;
    ocultarMaternidad: boolean = false;
    ocultarPrecioFijoEstu: boolean = false;
    ocultarTablaPrecio: boolean = false;
    ocultarMensualidadPrecio: boolean = false;

    @ViewChild('gralCLick')
    gralCLick!: ElementRef<HTMLElement>;

    file: any = '';
    display: any = 'Ver PDF';
    clase: any = 'btn btn-outline-primary';
    url:any="https://sistema.genomi-k.com/responsivegk/ng_propuesta_pdf.cfm?user="+btoa('ngUsrGK')+"&pass="+btoa('ngGenomi-k2023!.123')+"&propuestaid=";

    ngOnInit(): void {
        this.LoadGenerales();
        this.router.queryParams.subscribe(params => {
            if (params['esVisor'] === 'true') {
                this.esVisor = true;
            }
        });
    }

    triggerFalseClick() {
        const el: HTMLElement = this.gralCLick.nativeElement;
        el.click();
    }

    LoadGenerales() {
        if(location.hostname.toLowerCase().indexOf("localhost") != -1) {
            this.url = "https://demo.genomi-k.com/responsivegk/ng_propuesta_pdf.cfm?user="+btoa('ngUsrGK')+"&pass="+btoa('ngGenomi-k2023!.123')+"&propuestaid=";
        }
        this.triggerFalseClick();
    }

    //ToDos: Formulario
    @Input() public form!: FormGroup;
    @Input() public formTamik!: FormGroup;
    @Input() public formAudik!: FormGroup;
    @Input() public formCardik!: FormGroup;
    @Input() public formDx!: FormGroup;
    @Input() public formFact!: FormGroup;

    //ToDos Mensajes
    showMsg(titulo: string, mensaje: string){
        this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: titulo, 
                    mensaje: mensaje,
                    Noconfirm: true
                }
        })
    }

    //TodOs Selects

    changeTipoCostoIdA(value: any) {
        if(!value){
            this.ocultarPrecioFijoEstu = false;
            this.ocultarTablaPrecio = false;
            this.ocultarMensualidadPrecio = false;
            return
        }
        else if(value == 3){
            this.ocultarPrecioFijoEstu = true
            this.ocultarTablaPrecio = false;
            this.ocultarMensualidadPrecio = false;
        }else if(value == 4){
            this.ocultarTablaPrecio = true
            this.ocultarMensualidadPrecio = false;
            this.ocultarPrecioFijoEstu = false;
        }else{
            this.ocultarMensualidadPrecio = true
            this.ocultarPrecioFijoEstu = false;
            this.ocultarTablaPrecio = false
        }
    }

    //ToDos campos invalidos
    isValidFieldGeneral( field: string ): boolean | null{
        return this.form.controls[field].errors 
        && this.form.controls[field].touched;
    }

    isValidFieldTamik( field: string ): boolean | null{
        return this.formTamik.controls[field].errors 
        && this.formTamik.controls[field].touched;
    }

    isValidFieldAudik( field: string ): boolean | null{
        return this.formAudik.controls[field].errors 
        && this.formAudik.controls[field].touched;
    }

    isValidFieldCardik( field: string ): boolean | null{
        return this.formCardik.controls[field].errors 
        && this.formCardik.controls[field].touched;
    }

    isValidFieldDx( field: string ): boolean | null{
        return this.formDx.controls[field].errors 
        && this.formDx.controls[field].touched;
    }
    


    //TodOs Actualizar valores tablas

    updateValueCostAudik() {
        const costoAudikValue = this.formAudik.get('CostoAudik')!.value;
        this.formAudik.patchValue({
            CostoAudikTabla: costoAudikValue,
        })
        this.formCardik.patchValue({
            CostoAudik: costoAudikValue
        })
    }

    updateValueCostCardik(){
        const costoCardikValue = this.formCardik.get('CostoCardik')!.value;
        const costoAudikValue = this.formCardik.get('CostoAudik')!.value;
        const total = costoCardikValue+costoAudikValue;
        this.formCardik.patchValue({
            TotalCostos: total
        })
    }

    isSelectedPTamik(value: number): boolean {
        return this.formTamik.get('ProgramasTamik')!.value.includes(value);
    }

    isReadOnlyTamikP(): boolean {
        return this.formTamik.get('TipoCostoIdTamik')!.value == 2;
    }

    updateMonedaCardik() {
        const monedaValue = this.formAudik.get('TipoMonedaIdAudik')!.value;
        this.formCardik.patchValue({
            TipoMonedaIdAudik: monedaValue
        })
    }



    //ToDoS Modales

    subirConvenio(contrato?:any) {

        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        const zonaId = this.form.get('ZonaId')?.value;
        if(!contrato){
            const dialogRef = this.dialog.open(ConvenioMComponent,{
                data: { combos: this.combos, institucionid: institucionid , zonaId: zonaId },
                width: '924px',
            })
    
            dialogRef.componentInstance.convenioAdded.subscribe((newData: any) => {
                this.tabs.Generales.Tablas.Contratos = newData; // Actualizar los datos en el hijo
                this.dataChangedConvenio.emit(newData); // Emitir el evento con los nuevos datos al padre
            });
        }else{
            const dialogRef = this.dialog.open(ConvenioMComponent,{
                data: { combos: this.combos, institucionid: institucionid, contrato:contrato },
                width: '924px',
            })

            dialogRef.componentInstance.convenioAdded.subscribe((newData: any) => {
                this.tabs.Generales.Tablas.Contratos = newData; // Actualizar los datos en el hijo
                this.dataChangedConvenio.emit(newData); // Emitir el evento con los nuevos datos al padre
            });
        }
    }

    agregarTamiz(tamizaje?:any) {
        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        if(!tamizaje){
            const dialogRef = this.dialog.open(TamizajeMComponent,{
                data: { combos: this.combos, institucionid: institucionid },
                width: '724px'
            })

            dialogRef.componentInstance.tamizajeAdded.subscribe((newData: any) => {
                this.tabs.Generales.Tablas.Tamizajes = newData; // Actualizar los datos en el hijo
                this.dataChangedTamizajes.emit(newData); // Emitir el evento con los nuevos datos al padre
            });
        }else{
            const dialogRef = this.dialog.open(TamizajeMComponent,{
                data: { combos: this.combos, institucionid: institucionid, tamizaje: tamizaje },
                width: '724px'
            })

            dialogRef.componentInstance.tamizajeAdded.subscribe((newData: any) => {
                this.tabs.Generales.Tablas.Tamizajes = newData; // Actualizar los datos en el hijo
                this.dataChangedTamizajes.emit(newData); // Emitir el evento con los nuevos datos al padre
            });
        }
        
    }

    eliminarTamizP(tamizaje:any){
        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        const id = tamizaje.toString();
        const tamizajeDelete = {
            institucionid,
            InstitucionTamizajeId: id
        }
        
        const dialogRef = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: `Eliminacion Paquete de tamizaje`, 
                    mensaje: `¿Esta seguro de querer eliminar el paquete?` }
        })

        dialogRef.afterClosed().subscribe(confirmo => {
            if (confirmo) {
                this.appCommon.spinner(true);
                this.customersServices.deleteTamizaje(tamizajeDelete).subscribe({
                    next: (resp:any) => {
                        this.appCommon.spinner(false);
                        this.showMsg('Eliminacion exitosa', 'Se ha eliminado el paquete de tamizaje');
                        this.dataChangedTamizajes.emit(resp.data);
                    }, 
                    error: err => {
                        this.appCommon.spinner(false);
                        this.showMsg('No se ha podido eliminar', 'A ocurrido un error al querer eliminar el paquete');
                        console.log('error', err);
                    }
                })
            }
        });

        
    }

    agregarMaternidad() {
        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        const dialogRef = this.dialog.open(PaqueteMaterMComponent,{
            data: { combos: this.combos, institucionid: institucionid },
            width: '724px'
        })

        dialogRef.componentInstance.paqueteAdded.subscribe((newData: any) => {
            this.tabs.Generales.Tablas.PaquetesMaternidad = newData; // Actualizar los datos en el hijo
            this.dataChangedPaquetes.emit(newData); // Emitir el evento con los nuevos datos al padre
        });
    }

    eliminarPMater(paqueteM: any) {
        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        const id = paqueteM.toString();
        const paqueteMDelete = {
            institucionid,
            InstitucionPaqueteId: id
        }
        const dialogRef = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: `Eliminacion Paquete de maternidad`, 
                    mensaje: `¿Esta seguro de querer eliminar el paquete?` }
        })

        dialogRef.afterClosed().subscribe(confirmo => {
            if (confirmo) {
                this.appCommon.spinner(true);
                this.customersServices.deletePaqueteMater(paqueteMDelete).subscribe({
                    next: (resp:any) => {
                        this.appCommon.spinner(false);
                        this.showMsg('Eliminacion exitosa', 'Se ha eliminado el paquete de maternidad');
                        this.dataChangedPaquetes.emit(resp.data);
                    }, 
                    error: err => {
                        this.appCommon.spinner(false);
                        this.showMsg('No se ha podido eliminar', 'A ocurrido un error al querer eliminar el paquete');
                        console.log('error', err);
                    }
                })
            }
        });

    }

    eliminarConvenio(id: number) {
        if(this.esVisor){
            this.showMsg('No es posible realizar esta accion', 'Solo es posible visualizar el formulario');
            return;
        }
        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        const convenio = {
            institucionid,
            ContratoId: id
        }

        const dialogRef = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: `Eliminacion de Convenio`, 
                    mensaje: `¿Esta seguro de querer eliminar el convenio?` }
        })

        dialogRef.afterClosed().subscribe(confirmo => {
            if (confirmo) {
                this.appCommon.spinner(true);
                this.customersServices.deleteConvenio(convenio).subscribe({
                    next: (resp:any) => {
                        this.appCommon.spinner(false);
                        this.dataChangedConvenio.emit(resp.data);
                        this.showMsg('Eliminacion exitosa', 'Se ha eliminado el convenio');
                    }, 
                    error: err => {
                        this.appCommon.spinner(false);
                        this.showMsg('No se ha podido eliminar', 'A ocurrido un error al querer eliminar el convenio');
                        console.log('error', err);
                    }
                })
            }
        });

    }

    showImageConvenio(id: number) {
        this.appCommon.spinner(true);
        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        const contrato = {
            ContratoInstitucionid: id, institucionid
        }

        this.customersServices.getContratoConvenio(contrato).subscribe({
            next: (resp:any) => {
                this.appCommon.spinner(false);
                if(resp.data.Archivo){
                    this.dialog.open(ShowImageMComponent, {
                        data: { imagen: resp.data.Archivo, titulo: resp.data.Contrato }
                    })
                }else{
                    this.showMsg('No se ha podido encontrar el contrato', 'Favor de hablar con un administrador.');
                }
            },
            error: err => {
                this.appCommon.spinner(false);
                this.showMsg('No se ha podido cargar la imagen del contrato', 'A ocurrido un error al querer visualizar la imagen del contrato');
                console.log('No se ha podido encontrar la informacion', err);
            }
        })
    }

    downloadPdfConvenio(id: number) {
        this.appCommon.spinner(true);
        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        const contrato = {
            ContratoInstitucionid: id, institucionid
        }

        this.customersServices.getContratoConvenio(contrato).subscribe({
            next: (resp:any) => {
                this.appCommon.spinner(false);
                if(resp.data.Archivo){
                    this.vS.downloadPDF(resp.data.Archivo, resp.data.Contrato);
                }else{
                    this.showMsg('No se ha podido encontrar el contrato', 'Favor de hablar con un administrador.');
                }
            },
            error: err => {
                this.appCommon.spinner(false);
                this.showMsg('No se ha podido cargar la imagen del contrato', 'A ocurrido un error al querer visualizar la imagen del contrato');
                console.log('No se ha podido encontrar la informacion', err);
            }
        })
    }
    
    editarPMater(paquete) {
        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        const dialogRef = this.dialog.open(PaqueteMaterMComponent,{
            data: { combos: this.combos , paquete: paquete, institucionid:institucionid },
            width: '724px'
        })

        dialogRef.componentInstance.paqueteAdded.subscribe((newData: any) => {
            this.tabs.Generales.Tablas.PaquetesMaternidad = newData; // Actualizar los datos en el hijo
            this.dataChangedPaquetes.emit(newData); // Emitir el evento con los nuevos datos al padre
        });
    }

    tablaPrecios(id){
        this.appCommon.spinner(true);
        const precio = {
            tipotablaprecio: parseFloat(id)
        };

        this.customersServices.getTablaPrecio(precio).subscribe({
            next: (resp:any) => {
                this.appCommon.spinner(false);
                this.dialog.open(TablaPreciosMComponent,{
                    data: { tipotablaprecio: resp},
                    width: '724px'
                })
            },
            error: err => {
                this.appCommon.spinner(false);
                this.showMsg('No se ha podido encontrar la informacion', 'A ocurrido un error al querer visualizar la tabla de precios');
                console.log('No se ha podido encontrar la informacion', err);
            }
        })

    }

    agregarDoctor(doctor?:any) {
        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        if(doctor){
            const dialogRef = this.dialog.open(DoctorMComponent, {
                data: { combos: this.combos , institucionid: institucionid, doctor: doctor },
                width: '850px'
            })

            dialogRef.componentInstance.doctorAdded.subscribe((newData: any) => {
                this.tabs.Generales.Tablas.DoctoresECO = newData; // Actualizar los datos en el hijo
                this.dataChangedDoctorEco.emit(newData); // Emitir el evento con los nuevos datos al padre
            });
        }else{
            const dialogRef = this.dialog.open(DoctorMComponent, {
                data: { combos: this.combos , institucionid: institucionid },
                width: '850px'
            })

            dialogRef.componentInstance.doctorAdded.subscribe((newData: any) => {
                this.tabs.Generales.Tablas.DoctoresECO = newData; // Actualizar los datos en el hijo
                this.dataChangedDoctorEco.emit(newData); // Emitir el evento con los nuevos datos al padre
            });
            
        }
    }

    showImageDoctor(id: number) {
        this.appCommon.spinner(true);
        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        const idDoctor = id.toString();
        const doctor = {
            Id: idDoctor, institucionid
        }

        this.customersServices.getDoctorEco(doctor).subscribe({
            next: (resp:any) => {
                this.appCommon.spinner(false);
                if(resp.data[0].Firma){
                    this.dialog.open(ShowImageMComponent, {
                        data: { imagen: resp.data[0].Firma, titulo: resp.data[0].NombreFirma }
                    })
                }else{
                    this.showMsg('No se ha podido encontrar la Firma', 'Favor de hablar con un administrador.');
                }
            },
            error: err => {
                this.appCommon.spinner(false);
                this.showMsg('No se ha podido cargar la Firma', 'A ocurrido un error al querer visualizar la Firma');
                console.log('No se ha podido encontrar la informacion', err);
            }
        })
    }
        
        

    savePlantillas() {
        if(this.esVisor){
            this.showMsg('No es posible realizar esta accion', 'Solo es posible visualizar el formulario');
            return;
        }
        this.appCommon.spinner(true);
        const institucionid:number  = parseFloat(this.router.snapshot.params.id);
        const { bitPlanTk, bitPlanAk, bitPlanDx, bitPlanCk } = this.formFact.value;
        const bitPlanTkValor = bitPlanTk ? 1 : 0;
        const bitPlanAkValor = bitPlanAk ? 1 : 0;
        const bitPlanDxValor = bitPlanDx ? 1 : 0;
        const bitPlanCkValor = bitPlanCk ? 1 : 0;
        const plantilla = { 
            institucionid, bitPlanTk: bitPlanTkValor, bitPlanAk:bitPlanAkValor, 
            bitPlanDx: bitPlanDxValor, bitPlanCk:  bitPlanCkValor
        };
        this.customersServices.savePlantillas(plantilla)
            .subscribe(
                {
                next: (resp:any) => {
                    this.appCommon.spinner(false);
                    this.showMsg('Plantillas guardadas', 'Se han guardado las plantillas');
                },
                error: err => {
                    this.appCommon.spinner(false);
                    this.showMsg('No se pudo guardar las plantillas', 'A ocurrido un error al querer guardar las plantillas');
                    console.log('error al enviar la informacion', err)
                } 
            }
            )
    }
        

    
}
