import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppCommonService } from '@common/services';
import { CustomersService } from '@modules/customers/services';
import { ValidatorService } from '@modules/validators/validator.service';
import { ConfirmacionMComponent } from '../confirmacion-m/confirmacion-m.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'gk-convenio-m',
  templateUrl: './convenio-m.component.html',
  styleUrl: './convenio-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConvenioMComponent implements OnInit {

  //? combos recibidos
  combos: any;
  institucionid: any;
  file:any;
  contrato: any;
  @Output() convenioAdded = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  
  private fb = inject(FormBuilder);
  private customersService= inject(CustomersService);
  private dialogRef= inject(MatDialogRef<ConvenioMComponent>);
  private vS = inject(ValidatorService);
  public appCommon= inject(AppCommonService);
  private dialog = inject(MatDialog);
  private router = inject(ActivatedRoute);

  esVisor:boolean = false;
  
  public formConvenio: FormGroup = this.fb.group({
    aperturacliente: [],
    tipoContratoId: [,Validators.required],
    expiraContrato: [],
    fechaExpira: [null] ,
    zonaId: [],
    tamik: [],
    audik: [],
    dx: [],
    cardik: []
  })
  
  //ToDos campos invalidos
  isValidFieldConvenio( field: string ): boolean | null{
    return this.formConvenio.controls[field].errors 
    && this.formConvenio.controls[field].touched;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,){
    if(this.data && this.data.combos && this.data.institucionid && !this.data.contrato ){
      this.combos = this.data.combos;
      this.institucionid = this.data.institucionid;
    }
    if (this.data && this.data.combos && this.data.institucionid && this.data.contrato){
      this.combos = this.data.combos;
      this.institucionid = this.data.institucionid;
      this.contrato = this.data.contrato;
    }
    this.formConvenio.patchValue({
      zonaId: this.data.zonaId
    });
  }
  ngOnInit(): void {

    this.router.queryParams.subscribe(params => {
      if (params['esVisor'] === 'true') {
          this.esVisor = true;
      }
    });

    this.formConvenio.get('aperturacliente')!.valueChanges.subscribe(value => {
      if (value == 0) {
        this.clearCheckboxes();
      }
    });

    this.formConvenio.get('expiraContrato')!.valueChanges.subscribe(value => {
      if (value == 0) {
        this.clearFecha();
      }
    });
  }

  clearCheckboxes(): void {
    this.formConvenio.patchValue({
      tamik: false,
      audik: false,
      dx: false,
      cardik: false
    });
  }

  clearFecha(): void {
    this.formConvenio.patchValue({
      fechaExpira: null,
    });
  }

  archivoSeleccionado(event:any) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const nombre = selectedFile.name;
      this.vS.convertToBase64(selectedFile).then((base64String: string) => {
        const archivo = base64String;
        this.file = {
          nombre,
          archivo
        }
      });
    }else{
      const archivo = "";
      const nombre = "";
        this.file = {
          nombre,
          archivo
        }
    }
  }

  generarSoluciones(formConValue:any){
    const { tamik, audik, dx, cardik } = this.formConvenio.value;
    const bitPlanTkValor = tamik ? 1 : 0;
    const bitPlanAkValor = audik ? 1 : 0;
    const bitPlanDxValor = dx ? 1 : 0;
    const bitPlanCkValor = cardik ? 1 : 0;
    const soluciones = { 
      tamik: bitPlanTkValor, audik:bitPlanAkValor, 
      dx: bitPlanDxValor, cardik:  bitPlanCkValor
    };
    delete formConValue.tamik;
    delete formConValue.audik;
    delete formConValue.dx;
    delete formConValue.cardik;
    delete formConValue.aperturacliente;
    delete formConValue.zonaId;
    formConValue.soluciones = soluciones;

    formConValue.tipoContratoId = parseFloat(formConValue.tipoContratoId);

    return formConValue

  }
    

  saveConvenio(){

    if(this.formConvenio.invalid){
      const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
        data: { titulo: `formulario invalido`, 
                mensaje: `Favor de terminar el formulario`,
                Noconfirm: true
            }
      })
      dialogRefM.afterOpened().subscribe(() => {
        setTimeout(() => {
          dialogRefM.close();
        }, 3000);
      });
      this.formConvenio.markAllAsTouched();
      console.log(this.formConvenio.value);
      return;
    }

    this.appCommon.spinner(true);

    //? Desestructuro el objeto 
    const formConValue = { ...this.formConvenio.value }

    formConValue.institucionid = this.institucionid;

    if(this.file){
      console.log(this.file);
      formConValue.file = this.file;
    }else{
      const file = {
        nombre: "",
        archivo: ""
      }
      formConValue.file = file;
    }

    this.generarSoluciones(formConValue);

    console.log('form regresado:', formConValue);
    

    this.customersService.saveConvenio(formConValue).subscribe({
      next: (resp:any) => {
        this.appCommon.spinner(false);
        const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
          data: { titulo: `Paquete agregado`, 
                  mensaje: `Se ha guardado el paquete`,
                  Noconfirm: true
              }
        })
        dialogRefM.afterOpened().subscribe(() => {
          setTimeout(() => {
            dialogRefM.close();
          }, 3000);
        });
        this.convenioAdded.emit(resp.data);
        this.dialogRef.close();
        console.log('sucess', resp);
      },
      error: err => {
        this.appCommon.spinner(false);
        const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
          data: { titulo: `No se pudo guardar la informacion`, 
                  mensaje: `A ocurrido un error al querer guardar el paquete de Maternidad`,
                  Noconfirm: true
                }
          })

        dialogRefM.afterOpened().subscribe(() => {
          setTimeout(() => {
            dialogRefM.close();
          }, 3000);
        });
        console.log('ha ocurrido un error', err);
      }
    })

  }


}
