<form novalidate [formGroup]="form" (ngSubmit)="validateForm()">
    <input formControlName="AlertaId" type="hidden" />

    <div class="modal-content">
        <div class="modal-header ttdgray white" style="background-color: rgb(207, 205, 205); padding: 20px">
            <div class="row">
                <div class="col-md-9">
                    <h2 class="modal-title white font-weight-bold">{{ Titulo }}</h2>
                </div>

                <div class="col-md-3 text-right">
                    <button type="button" class="btn btn-default btnMail me-3" (click)="dismiss()">
                        <fa-icon class="mr-1" [icon]="['fas', 'times']"></fa-icon>
                        Cerrar
                    </button>

                    <button type="submit" class="btn btn-success btnMail">
                        <fa-icon class="mr-1" [icon]="['fas', 'envelope']"></fa-icon>
                        Enviar
                    </button>
                </div>
            </div>
        </div>

        <div class="modal-body">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-2">
                                <label for="" style="padding: 10px 5px; font-size: 18px">From:</label>
                            </div>
                            <div class="col-md-10">
                                {{ From }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="" style="padding: 10px 5px; font-size: 18px">To:</label>
                            </div>
                            <div class="col-md-10">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-select nzSize="large" nzMode="tags" [nzTokenSeparators]="[',']" formControlName="To">
                                            <nz-option
                                                *ngFor="let option of listOfEmailOptions"
                                                [nzLabel]="option.label"
                                                [nzValue]="option.value"
                                            ></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                                
                                <div
                                    *ngIf="form.controls['To'].invalid && (form.controls['To'].dirty || form.controls['To'].touched)"
                                    class="error"
                                >
                                    <div *ngIf="form.controls['To']?.errors?.required">Este campo es obligatorio.</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="" style="padding: 10px 5px; font-size: 18px">Bcc:</label>
                            </div>
                            <div class="col-md-10">
                                {{ Bcc }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="" style="padding: 10px 5px; font-size: 18px">Subject:</label>
                            </div>
                            <div class="col-md-10">
                                <input formControlName="Subject" type="text" class="form-control" />
                                <div
                                    *ngIf="
                                        form.controls['Subject'].invalid &&
                                        (form.controls['Subject'].dirty || form.controls['Subject'].touched)
                                    "
                                    class="error"
                                >
                                    <div *ngIf="form.controls['Subject']?.errors?.required">Este campo es obligatorio.</div>
                                </div>
                            </div>
                        </div>
                        <div class="row" [hidden]="Attachment == undefined">
                            <div class="col-md-2">
                                <label for="" style="padding: 10px 5px; font-size: 18px">Attach:</label>
                            </div>
                            <div class="col-md-10">
                                <div class="input-group">
                                    <input
                                        formControlName="Attachment"
                                        type="text"
                                        class="form-control"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        id="inpAdjuntos"
                                    />
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="button" (click)="toggleAttachment()">
                                            <span [hidden]="showAttachment">
                                                <fa-icon class="mr-1" [icon]="['fas', 'folder']"></fa-icon>
                                                Administrar
                                            </span>
                                            <span [hidden]="!showAttachment">
                                                <fa-icon class="mr-1" [icon]="['fas', 'folder-open']"></fa-icon>
                                                Cerrar
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" [hidden]="!showAttachment">
                            <div class="col-md-2">&nbsp;</div>
                            <div class="col-md-8 table-responsive">
                                <table class="table table-striped table-bordered row-border hover nowrap" style="width: 100%">
                                    <thead>
                                        <tr>
                                            <td align="left" width="90%" class="ttdblue">Archivo adjunto</td>
                                            <td align="center" width="10%" class="ttdblue">Acciones</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of form.value.Attachment">
                                            <td align="left" class="bg-white">
                                                <a href="Rep_protocolo_182934_1745.pdf" target="_blank" class="txtlinka">
                                                    {{ item }}
                                                </a>
                                            </td>
                                            <td align="center" class="bg-white"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-10 offset-2">
                        <quill-editor formControlName="Body"></quill-editor>
                        <!-- <ckeditor #myEditor [editor]="editor" [config]="editorConfig" formControlName="Body"></ckeditor> -->
                    </div>
                </div>
                <div class="row mt-2" *ngIf="note !== ''">
                    <div class="col-md-10 offset-2"><b>Nota:</b> {{ note }}</div>
                </div>
            </div>
        </div>
    </div>
</form>
